@charset "utf-8";
.article_list {
  padding: .5em;
  @media (min-width: 768px) {
    padding: 1em;
  }
  position: relative;
  overflow: hidden;
  &:nth-child(even) {
    background: $color_highlight_bg;
  }
  &:before {
    $size: 20;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: $size+px;
    height: $size+px;
    background: transparent;
    transform: translate(0 - ($size/2)+px, 0 - ($size/2)+px) rotate(45deg);
    transition: .3s;
  }
  &:hover {
    &:before {
      background: $color_primary;
    }
  }
}

.pagination {
  >* {
    display: inline-block;
    padding: .5em;
    border: 1px solid #ccc;
  }
  a {
    text-decoration: none;
    background: $color_highlight_bg;
    transition: .3s;
    &:hover {
      background: #fff;
    }
  }
  span {
  }
}
