@charset "utf-8";

/*****************************
ヘッダー
******************************/

.head_option {
  position: absolute;
  right: 0;
  top: 0;

  @media (max-width: 768px) {
    display: none;
  }

  .head_tel {
    margin-right: 20px;
    display: inline-block;
  }

  .head_contact {
    display: inline-block;

    p a {
      padding: 0 3em;
      height: 102px;
      line-height: 102px;
      background-color: $color_secondry;
      color: #FFF;
      white-space: nowrap;

      &:before,&:after {border-color: #FFF;}
    }
  }
}//head_option

@media (min-width: 769px) {
  header .title a {margin-right: 420px;}
}



header .global_nav {
  background-color: #b09979;

  @media (min-width: 769px) {
    ul {
      margin: 0 auto;
      max-width: 1200px;
      width: 100%;

      li {
        padding: 0;
        width: 20%;
        text-align: center;

        a {
          padding: 0;
          height: 67px;
          line-height: 67px;
          display: block;
          position: relative;
          color: #FFF;
          @include fontsize(18);
          font-weight: 500;
          text-shadow: 0 1px 1px #333;

          &:hover {
            background-color: rgba(#000,.2);
            border-bottom: none;
            color: #FFF;
          }
        }//a

        &:nth-child(n+2) a {
          &:before {
            content: "";
            height: 43px;
            width: 1px;
            background-color: #746d64;
            position: absolute;
            left: 0;
            top: 12px;
          }
          &:after {
            content: "";
            height: 43px;
            width: 1px;
            background-color: #e7c99f;
            position: absolute;
            left: 1px;
            top: 12px;
          }
        }
      }//li
    }//ul
  }//@media
}//header .global_nav

.main_visual .main_visual_copy > li img {
  opacity: 0;
  &.animated {
    opacity: 1;

    @media (max-width: 600px) {
      transform: none!important;
    }
  }

  &.main_copy01 {
    transition: 1s;
    transform: translateX(500px);
    &.animated {transform: translateX(300px);}

    @media (max-width: 999px) {
      transform: translateX(450px);
      &.animated {transform: translateX(250px);}
    }
    @media (max-width: 799px) {
      transform: translateX(400px);
      &.animated {transform: translateX(200px);}
    }
  }
  &.main_copy02 {
    transition: 1.5s .5s;
    transform: scale(.8) rotate(20deg) translateX(300px);
    &.animated {transform: translateX(300px);}

    @media (max-width: 999px) {
      transform: scale(.8) rotate(20deg) translateX(250px);
      &.animated {transform: translateX(250px);}
    }
    @media (max-width: 799px) {
      transform: scale(.8) rotate(20deg) translateX(200px);
      &.animated {transform: translateX(200px);}
    }
  }
  &.main_copy03 {
    transition: .5s 2.5s;
    transform: translate(300px,50px);
    &.animated {transform: translate(300px,0);}

    @media (max-width: 999px) {
      transform: scale(2) rotate(20deg) translateX(250px);
      &.animated {transform: translateX(250px);}
    }
    @media (max-width: 799px) {
      transform: scale(2) rotate(20deg) translateX(200px);
      &.animated {transform: translateX(200px);}
    }
  }
  &.main_copy04 {
    transition: .6s 1.5s;
    transform: translateX(350px);
    &.animated {transform: translateX(300px);}

    @media (max-width: 999px) {
      transform: translateX(300px);
      &.animated {transform: translateX(250px);}
    }
    @media (max-width: 799px) {
      transform: translateX(250px);
      &.animated {transform: translateX(200px);}
    }
  }
  &.main_copy05 {
    transition: .6s 2s;
    transform: translateX(350px);
    &.animated {transform: translateX(300px);}

    @media (max-width: 999px) {
      transform: translateX(300px);
      &.animated {transform: translateX(250px);}
    }
    @media (max-width: 799px) {
      transform: translateX(250px);
      &.animated {transform: translateX(200px);}
    }
  }
  // &.main_copy05 {
  //   transition: .6s 2s;
  //   transform: translateX(50px);
  //   &.animated {transform: translateX(300px);}
  //
  //   @media (max-width: 999px) {
  //     transform: translateX(0);
  //     &.animated {transform: translateX(250px);}
  //   }
  //   @media (max-width: 799px) {
  //     transform: translateX(-50px);
  //     &.animated {transform: translateX(200px);}
  //   }
  // }
}

body.home {
  h2 {
    padding: 15px 0 0;
    background: url(/wp-content/uploads/title_bg001.png) no-repeat center top;
    min-height: 72px;
    @include fontsize(31);
    @media (max-width: 767px) {
      @include fontsize(22);
      text-align: center;
    }

    &:before,&:after {content: none;}
  }//h2

  h3 {
    margin-bottom: 0;
    color: $color_secondry;
    @include fontsize(25);

    &:before,&:after {content: none;}
  }//h3
}

.top_con02 {
  .box {
    padding: 2em;
    background-color: #FFF;
  }

  .more {
    margin-top: 1.5em;
    width: 100%;

    .add_arrow {
      padding: .6em 0 .6em 1em;
      width: 100%;
      background-color: $color_secondry;
      border-radius: 6px;
      color: #FFF;
      &:before,&:after {border-color: #FFF;}
    }//add_arrow

    &:hover a:before {content: none;}
  }//more

}//top_con02

.top_con03 {
  .container {
    padding: 50px;
    background-color: #FFF;
    border: 1px solid #cec5b8;
    box-shadow: 10px 10px 0 #dbd0c2;
    position: relative;

    &:before {
      content: url(/wp-content/uploads/icon_pin.png);
      position: absolute;
      left: -12px;
      top: -12px;
    }

    &:after {
      content: url(/wp-content/uploads/icon_pin.png);
      position: absolute;
      right: -12px;
      bottom: -12px;
    }
  }
}//.top_con03

.top_con04 {
  padding: 140px 0 0;
  background: url(/wp-content/uploads/top_bg001.png) no-repeat center top;

  @media (min-width: 768px) {
    height: 700px;
  }
  @media (max-width: 767px) {
    padding: 50px 0;
    background: rgba(#80602d,.24);
  }
}//.top_con04

.top_con05 {
  figure {
    img {
      width: 100%;
      @media (min-width: 768px) {
        padding: 0 100px 0 0;
      }
    }
  }
}

table.defalut {
  width: 100%;
  tbody {
    th {
      padding: 1em 1em 1em 2em;
      background: url(/wp-content/uploads/title_bg002.png) no-repeat left center;
      text-align: left;
      border-top: 1px solid $color_primary;
    }
    td {
      border-top: 1px solid $color_primary;
      text-align: left;
    }

    tr:last-child {
      th,td {border-bottom: 1px solid $color_primary;}
    }
  }
}

h1,h2,h3,h4,h5,h6 {
  font-family: $HiraMin;
  font-weight: 500;
}

/*****************************
サブページ
******************************/

body:not(.home) {
  h1 {
    margin-top: 0;
    padding: 50px 0;
    background: url(/wp-content/uploads/subpage_h1_bg.png) repeat center top;

    &:before,&:after {
      content: none;
    }
  }

  h2 {
    padding: 15px 0 0;
    background: url(/wp-content/uploads/title_bg001.png) no-repeat center top;
    min-height: 72px;
    @include fontsize(31);
    @media (max-width: 767px) {
      @include fontsize(22);
      text-align: center;
    }

    &:before,&:after {content: none;}
  }//h2

  h3 {
    padding: 0;
    display: table;
    table-layout: fixed;
    margin: 0 auto 1.5em;
    position: relative;
    border: 0;
    word-wrap: break-word;
    font-weight: normal;
    font-size: 24px;
    font-size: 2.4rem;
    color: #1b1b1b;

    &:before {
      right: calc(100% + 15px);
    }
    &:after {
      left: calc(100% + 15px);
      border: none;
    }

    &:before,&:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      height: 2px;
      background: #000;
      width: 60px;
    }
  }//h3
}//body:not(.home)

.contact {
  background-color: #FFF;
  color: $color_primary;
  border: 2px solid $color_primary;

  .teltext {
    line-height: 1.2;
    @include fontsize(44);
    font-family: $HiraMin;
    @media (max-width: 767px) {
      @include fontsize(38);
    }
    a {
      color: $color_primary;
      text-decoration: none;
    }
  }
  .time_title {
    display: inline-block;
    margin-right: 10px;
    padding: 0 1em;
    background-color: $color_primary;
    color: #FFF;
    border-radius: 4px;
    line-height: 1.4;
  }
  .more.wide {
    width: 100%;
    a {
      width: 100%;
      color: $color_primary;
      border-color: $color_primary;
      &:before {
        background: $color_primary;
      }
      &:after {
        border-color: $color_primary;
      }
      &:hover {color: #FFF;}
      &:hover:after {border-color: #FFF;}
    }
  }
}//contact

.tokucyo {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #FFF;
  border: 1px solid rgba($color_primary,.5);
  box-shadow: 6px 6px 0 rgba($color_primary,.5);

  li {
    color: $color_primary;
    line-height: 2;
  }
}//.tokucyo
