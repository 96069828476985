@charset "utf-8";

.th03_post01 {
  .col {
    display: block;
    float: none;
    background: rgba($color_primary, .05);
    width: 100%;
    padding: 4% 2%;
    text-decoration: none;
    opacity: 1;
    transition: .3s;
    &:fitst-child {
      margin-left: 0;
    }
    .date {
      color: $color_primary;
      font-size: 11px;
      font-size: 1.1rem;
    }
    .content {
      color: #000;
      font-size: 13px;
      font-size: 1.3rem;
    }
    .more {
      position: relative;
      padding-right: 20px;
      &:before,
      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 5px;
        margin: auto;
        border-bottom: 2px solid #000;
        transition: .3s;
      }
      &:before {
        transform: rotate(-45deg);
      }
      &:after {
        transform: rotate(-135deg);
      }
    }
    &:hover {
      .more {
        text-decoration: underline;
        &:before,
        &:after {
          border-bottom: 2px solid #000;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .th03_post01 {
    &.tile {
      letter-spacing: -.40em;
    }
    .col {
      display: inline-block;
      vertical-align: middle;
      letter-spacing: normal;
      width: calc((100% - 24px) / 4);
      margin-left: 8px;
      color: #000;
      &:last-child {
        position: relative;
      }
      &:hover {
        opacity: .7;
      }
      .more {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: auto;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 200px;
        padding-right: 0;
        &:before,
        &:after {
          right: 30px;
        }
        &:before {
        }
        &:after {
        }
      }
      &:hover {
        .more {
          &:before,
          &:after {
            right: 25px;
          }
        }
      }
    }
  }
}
