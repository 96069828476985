@charset "utf-8";
.hps-slider.typeA.th02_post02 {
  .sp-mask {
    &:after,
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      background: rgba($color_primary, .7);
      z-index: 1;
      transition: .5s;
    }
    $val: ($container / 2)+px;
    &:before {
      left: calc(50% + #{$val});
    }
    &:after {
      right: calc(50% + #{$val});
    }
  }
  // arrows
  .sp-horizontal .sp-arrows {
    max-width: $container+px;
    top: 50%;
    margin: auto;
    position: absolute;
    transform: translateY(-50%);
    z-index: 1;
  }
  .sp-next-arrow,
  .sp-previous-arrow {
    top: 0;
    bottom: 0;
    margin: auto;
    width: 24px;
    width: 24px;
    z-index: 5;
    background-size: auto;
    background-color: transparent;
  }
  .sp-previous-arrow {
    left: -3%!important;
  }
  .sp-next-arrow {
    right: -3%!important;
  }
  .sp-buttons {
    display: none;
  }
  .sp-slide {
    overflow: hidden;
    img {
      position: absolute;
      top: -300%;
      left: -300%;
      right: -300%;
      bottom: -300%;
      margin: auto;
      width: 100%;
    }
    p {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: auto;
      display: block;
      width: 200px;
      text-align: center;
      background: hsl(0, 0%, 100%);
      vertical-align: bottom;
      transform: translate(-50%, -50%);
      transition: .3s!important;
      a {
        display: block;
        @include fontsize(16);
        text-decoration: none;
        padding: 19px 0;
        border: none;
      }
    }
  }
}
@media ( max-width : ($container - 1)+px ) {
  .hps-slider.typeA.th02_post02 {
    $val: (($container / 3) / 2)+px;
    .sp-mask {
      &:before {
        left: 584px;
        left: calc(50% + #{$val});
      }
      &:after {
        right: 584px;
        right: calc(50% + #{$val});
      }
    }
    // arrows
    .sp-horizontal .sp-arrows {
      max-width: ($container / 3)+px;
    }
    .sp-previous-arrow {
      left: -10%!important;
    }
    .sp-next-arrow {
      right: -10%!important;
    }
  }
}
